export const routes = {
  INDEX: {
    path: "/",
  },
  REPORTS: {
    path: "/reports",
  },
  SETTINGS: {
    path: "/reports/:report_id/settings",
  },
  ACCOUNT: {
    path: "/settings/account",
  },
  REPORTS_ACCOUNT: {
    path: "/reports/:report_id/settings/account",
  },
  CHECKLIST: {
    path: "/reports/:report_id/checklist",
  },
  CHECKLIST_NEW_GROUP: {
    path: "/reports/:report_id/checklist/tasks-groups/new-group",
  },
  CHECKLIST_TASKS_GROUP: {
    path: "/reports/:report_id/checklist/tasks-groups/:tasks-groups_id",
  },
  DOCUMENTS: {
    path: "/reports/:report_id/documents",
  },
  INFORMATION: {
    path: "/reports/:report_id/information",
  },
  HISTORY: {
    path: "/reports/:report_id/history",
  },
  REPORTS_LIST: {
    path: "/reports",
  },
  SIGN_IN: {
    path: "/sign-in",
  },
} as const
